.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    outline: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
}

.modal-background {
    position: fixed;
    top: 95px;
    right: 0;
    z-index: 00;
    background:var( --tm-blue);
    float: right;
    z-index: 100;
    border-left-style: solid;
    border-left: 1px;

}

.modal-body {
    position: relative;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    z-index: 100;
    animation-name: menu-animation;
}

@keyframes menu-animation{

}

.modal-menu-content {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    max-height: 30rem;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: var(--font-size-md);
    text-decoration: none;
    text-align: right;

}

.modal-menu-content li {
    list-style: none;
    text-decoration: none;
    margin-top: 40px;
    margin-bottom: 40px;
}
