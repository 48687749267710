
.navbar-logo {
    display: inline-flex;
}

.logo-image {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 90px;
}
.ptm-logo-text {
    width: auto;
    height: 100px;
    display: block;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.typography {
    font-family: 'Montserrat', sans-serif;    
    font-weight: 600;
    font-size: 1.75rem;
    margin: 1%;
    margin-top: auto;
    margin-bottom: auto;

}

@media only screen and (max-width: 600px) {
    .hidden {
        display: none;
    }
}