

.rectangle {

    position: relative;
    background: var( --tm-blue-grad);
    width: auto;
    height: auto;
    padding: var(--padding-small) ;
    transform: skewY(-5deg);
    display: flex;

}

.last-rectangle {

    position: relative;
    background: var( --tm-blue-grad);
    width: auto;
    height: auto;
    padding: var(--padding-small) ;
    display: flex;
    border-radius: 80px;
    margin: 25px;
}

.last-rectangle {

    position: relative;
    background: var( --tm-blue-grad);
    width: auto;
    height: auto;
    padding: var(--padding-small) ;
    display: flex;
    border-radius: 80px;
    margin: 25px;
}


.blockquote {
color: #fff;
width: auto;
display: table;
text-align: center;
margin: auto;
font-family: Source Sans Pro;
}
