.jumbo {
    display: flex;
    max-height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 85%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    align-items: self-end;
    justify-content: flex-end;
    top: 0;
    left: 0;
}


.background-video {
    overflow: hidden;
    object-fit: fill;
    max-height: 80vh;
    display: flex;
    top: 95px;
    left: 0;
    width: 100%;
    z-index: 2;
}

video {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: fill;
    max-height: 80vh;
    display: flex;
    z-index: 2;
    vertical-align: middle;
        border-radius: 25px;
}

.overlay-desc {
    z-index: 2;
    background: rgba(0, 0, 0, 0);
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.jumbo-text {
    text-align: center;
    background-color: white;
    opacity: 0.9;
    display: block;
    position: relative;
    padding: 1vh;
    z-index: 2; 
    border-radius: 25px;
}


.jumbo-text h2 {
    text-align: center;
    /* margin: 10px; */
}

.jumbo-text h3 {

    text-align: center;
    margin: 0; 
}
button.jumbo-text {
    opacity: 100%;
    z-index: 3;
}

/* .jumbo-text p {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    font-size: 20px; 
} */

@media only screen and (max-width: 950px) {
    .jumbo-text {
         z-index: 2;
        text-align: center;
        background-color: white;
        position: relative;
        padding: 2vh;
        margin-left: auto;
        margin-right: auto;
    }
    .overlay-desc {
         z-index: 2;
        background: rgba(0, 0, 0, 0);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .jumbo-text h2 {
        text-align: center;
    }

    .jumbo-text h3 {
        text-align: center;
    }
}

@media (min-width:500px) and (max-width: 950px) {
    .jumbo-text {
         z-index: 2;
        text-align: center;
        background-color: white;
        position: relative;
        padding: 2vh;
        margin-left: auto;
        margin-right: auto;
                display: flex;
        align-items: center;
        justify-content: center;
    }
    .overlay-desc {
         z-index: 2;
        background: rgba(0, 0, 0, 0);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .jumbo-text h2 {
        text-align: center;
    }

    .jumbo-text h3 {
        text-align: center;
    }
}