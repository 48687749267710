 .navbar {
    position: sticky;
    display: flex;
    height: 95px;
    padding: 1%;
    flex-direction: row;
    max-width: 100vw;
    background:var( --tm-blue-grad);
    color: #ffffff;
    vertical-align: baseline;
    z-index: 3;
    right: 0;
    left: 0;
    top: 0;
}


.header-info {
    width: auto;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 1.25rem;
}
/* .button {
    border-radius: 25px;
    background: #4bb543;
    padding: 20px;
    width: 180px;
    height: 25px;
    margin: 1;
    float: inline-end;
} */

.button li {
    text-decoration: none;
    color: white;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 1.25rem;
}

.navbar-menu {
    /* margin-top: px; */
    top: 50%;
    position: absolute;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    z-index: 3;
}

.header-menu li {
    display: flex;
    flex-direction: row;
    display: inline-flex;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Source Sans Pro;
    font-weight: 400;
    font-size: 1.25rem;
}

@media only screen and (max-width: 950px) {
    .header :nth-child(1) {
        order: 1;
    }
    .header :nth-child(2) {
        order: 2;
        align-content: flex-end;
    }

    .typography {
        margin-top: auto;
        margin-bottom: auto;
    }
    .header-image {
        width: 20vw;
        height: auto;
        display: block;
        justify-content: center;
        align-items: center;
        object-fit: contain;
    }
}
