.faq-body{
    min-height: 80vh;
    margin-left: 10vw;
    margin-right: 10vw;
    font-family: Source Sans Pro;
    font-weight: 400;
    font-size: 1.25rem;
}

.faq-body a{
    text-decoration: none;
    color: #772432;
}

.question-answer{
    padding-top: 20px;
}

.show-hide-image{
    padding-top: 25px;
    padding-right: 5px;
    display: inline-flex;
}

.faq-body h3{
    margin-top: 0;
                    
}

img {
    margin-right: 5px;
}