

.process-section {
    width: 22rem;
    padding: 15px;
    margin: 10px;
    text-justify: auto;
}

.process-section  img {

    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 15rem;
    height: 15rem;
    align-items: center;
    justify-content: center;

}

.join-us{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* @media only screen and (max-width: 1300px) {
    .process-section {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        /* flex: 50%; */
    /* max-width: 50%; 
    }
  } 
  */