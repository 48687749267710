.footer {
    position: absolute;
    width: 100vw;
    height: auto;
    background:var( --tm-blue-grad);
    margin-top: 2%;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-right: auto;
    padding-left: auto;
    font-family: Source Sans Pro;
}

.footerImage {
    width: auto;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}


.footer ul, li {
    margin-left: 0px;
    padding: 0px;
    list-style-type: none;
}

.tagline{
    width: 100%;
}


.footerSection  {
    display: flex;
    flex-direction: column;
    max-width: 40vw;
    flex-shrink: 5;
    flex-grow: 1;
}

.usefulLinks{
    align-items: flex-end;
    text-align: right;
    flex-direction: column;
    list-style-type: none;
    padding: 0px;
    margin: 15px 0;
}

.centreInDiv {
    margin-top: 10px;
    display: block;
    text-align: center;
}

.centre{
    align-items: center;
}

.button {
    color: rgb(19, 19, 19);
}



@media only screen and (max-width: 1000px) {
    .footer {
        position: absolute;
        width: 100vw;
        height: auto;
        background-color: #004165;
        margin-top: 2%;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

    }

    .usefulLinks {
        text-align: center;
        list-style-type: none;
      }


    .mobile-footerSection {
        /* On small screens, we are no longer using row direction but column */
        margin-left: 30px;
        margin-right: 30px;
        text-align: left;
        min-width: 200px;
        display: inline-block;
        justify-content: space-evenly;
        /* how much space taken flexing */
    }

    .footerImage {
        max-width: 200px;
        height: auto;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .tagline{
        max-width: 600px;
    }
    .footerSection {
        display: inline-block;
        justify-content: space-around;
        vertical-align: top;
        max-width: 100vw;
        align-items: center;
    }

    .footerPTMDetails {
        margin-left: auto;
        margin-right: auto;
    }

    .footerPTMDetails h4,
    h5,
    p {
        margin: 5px;
    }

    .footerPTMDetails p {
        font-size: 14px;
    }

    .footerPTMDetails h3 {
        font-size: 16px;
    }

    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

}
