.about-body {
    margin: var(--margin-large);
    display: flex;
    flex-direction: row;
    justify-content: center;
     align-items: center;

}

.about-body #image{
    display: flex;
    max-width: 450px;
    margin-right: 5px;
    /* height: 50vw; */
}


.text {
    text-align: left;
    margin-right: 5vw;
    display: inline-block;
}


.about-body video {
    max-width: 600px;
    align-items: center;
}

.join-us{
    font-family: Source Sans Pro;
    text-align: center;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inline-link{
    color: blue;
}
#video-about{
    height: auto;
    min-width: 600px;
    border-radius: 25px;
}
@media only screen and (max-width: 1400px) {
.about-body {
    flex-direction: column;
    }

    #video-about{
        min-width: auto;
    }
}

@media only screen and (max-width: 650px) {
    .about-body #image{
        max-width: 350px;
    }
}